// dependencies
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { StyleSheet, css } from "aphrodite";

// components
import OptGroup from "./OptGroup";
import Option from "./Option";
import Popover from "@gdf/resources/src/components/Popover3";
import { IconCaretDownO } from "@gdf/svg-icon-library";

// constants
import theming from "@gdf/resources/src/constants/theming";

// contexts
import { SelectProvider } from "./context";

// libraries
import { get } from "@gdf/shared/src/libraries";

const { useTheme } = theming;

type OptionType = {
  label: string;
  value?: number | string;
  disabled?: boolean;
  checked?: boolean;
  children?: Array<OptionType>;
};

const generateOptionListTree = ({
  optionList,
  depth = 0,
}: {
  optionList: Array<OptionType>;
  depth?: number;
}) => {
  return optionList.map((option, optionIndex) => {
    if (Array.isArray(option.children)) {
      if (option.children.length > 0) {
        const id = `${optionIndex}-${depth}`;

        return (
          <OptGroup key={id} label={option.label} depth={depth}>
            {generateOptionListTree({
              optionList: option.children,
              depth: depth + 1,
            })}
          </OptGroup>
        );
      }

      return null;
    } else {
      const id = `${option.value}-${optionIndex}-${depth}`;

      return (
        <Option
          key={id}
          value={option.value}
          depth={depth}
          disabled={option.disabled}
        >
          {option.label}
        </Option>
      );
    }
  });
};

const flatOptionList = ({ optionList, flattenOptionList = [] }) => {
  optionList.forEach((option) => {
    if (!Array.isArray(option.children)) {
      flattenOptionList.push(option);
    } else {
      flatOptionList({ optionList: option.children, flattenOptionList });
    }
  });

  return flattenOptionList;
};

const parseNestedChildren = ({ children }) => {
  const optionList = [];

  React.Children.forEach(children, (child) => {
    if (child && child.type) {
      switch (child.type) {
        case OptGroup: {
          optionList.push({
            label: child.props.label,
            children: parseNestedChildren({
              children: child.props.children,
            }),
          });

          break;
        }

        case Option: {
          optionList.push({
            label: child.props.children,
            value: child.props.value,
            disabled: true === child.props.disabled,
          });

          break;
        }
      }
    }
  });

  return optionList;
};

const getFirstNonDisabledOption = ({ fromIndex, optionList, incremental }) => {
  let nonDisabledOption = null;

  let i = fromIndex + (incremental ? 1 : -1);

  while (incremental ? i < optionList.length : i > 0) {
    const option = optionList[i];

    if (!option.disabled) {
      nonDisabledOption = option;
      break;
    }

    i += incremental ? 1 : -1;
  }

  return nonDisabledOption;
};

const styles = StyleSheet.create({
  select__native: {
    appearance: "none",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderColor: "#cfdadd",
    borderWidth: "0.0625rem",
    width: "100%",
    borderRadius: "0.1875rem",
    fontSize: "1rem",
    textIndent: "0.25rem",
    paddingRight: "2.75rem",
  },
  select__nativeSingle: {
    height: "2.625rem",
  },
  select__custom: {},
  wrapper: {
    position: "relative",
  },
  button: {
    fontSize: "1rem",
    borderColor: "#cfdadd",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "0.1875rem",
    paddingTop: "0.6875rem",
    paddingBottom: "0.6875rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    cursor: "default",
  },
  list: {
    backgroundColor: "#ffffff",
    marginTop: "1rem",
    borderColor: "#cfdadd",
    borderWidth: "0.0625rem",
    borderStyle: "solid",
    borderRadius: "0.1875rem",
  },
  caret: {
    position: "absolute",
    right: "0.375rem",
    top: "50%",
    fontSize: "0.625rem",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  },
  required: {
    position: "absolute",
    right: "0.1875rem",
    top: "0.125rem",
    color: "#ff0000",
    fontSize: "0.625rem",
  },
});

type PropsType = {
  name: string;
  native?: boolean;
  value: string | number | Array<string>;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  optionList?: OptionType[];
  selectProps?: any;
  onChange: (event: {
    target: { name: string; value: string | number | Array<string | number> };
  }) => void;
};

const Select: React.FunctionComponent<PropsType> = (props) => {
  const {
    native,
    value,
    optionList,
    placeholder,
    name,
    required,
    disabled,
    multiple,
    selectProps,
    children,
    onChange,
  } = props;

  const theme = useTheme();

  const [reference, setReference] = useState<HTMLElement>(null as any);

  const [hover, setHover] = useState(null);

  const [focused, setFocused] = useState(false);

  const [opened, setOpened] = useState(false);

  const $list = useRef<HTMLDivElement>();

  const normalizedValue: string | number | Array<string | number> = useMemo(
    function () {
      return multiple
        ? Array.isArray(value)
          ? value
          : [value]
        : !Array.isArray(value)
        ? value
        : value[0];
    },
    [multiple, value]
  );

  /**
   * Récupère la liste des options avec la profondeur.
   */
  const nestedOptionList = useMemo(() => {
    return [
      ...(placeholder
        ? [{ label: placeholder, value: "", disabled: multiple }]
        : []),
      ...(Array.isArray(optionList)
        ? optionList
        : parseNestedChildren({ children })),
    ];
  }, [optionList, children, multiple, placeholder]);

  const flattenOptionList = useMemo(
    () => flatOptionList({ optionList: nestedOptionList }),
    [nestedOptionList]
  );

  /**
   * Change la valeur pour le select (custom).
   */
  const setValueCustom = useCallback(
    (newValue) => {
      if (!disabled) {
        let tempValue;

        if (multiple) {
          tempValue = flattenOptionList.reduce((tempValue, option) => {
            if (
              (normalizedValue as Array<string | number>).includes(
                option.value
              ) &&
              newValue !== option.value
            ) {
              // Si la nouvelle valeur sélectionnée correspond à l'option courante,
              //   et que cette option n'est pas déjà activée,
              //   on l'active.
              tempValue.push(option.value);
            } else if (
              !(normalizedValue as Array<string | number>).includes(
                option.value
              ) &&
              newValue === option.value
            ) {
              // Si la nouvelle valeur sélectionnée ne correspond pas à l'option courante,
              //   mais étaient précédemment activée, on la laisse active.
              tempValue.push(option.value);
            }

            return tempValue;
          }, []);

          if (
            normalizedValue.length === 1 &&
            normalizedValue[0] === "" &&
            tempValue.length > 1
          ) {
            // Si la valeur précédemment sélectionnée était la valeur par défaut,
            //   on la décoche.

            tempValue.splice(0, 1);
          }

          if (tempValue.length === 0) {
            // Si la valeur ne contient rien, on coche la valeur par défaut.

            tempValue = [flattenOptionList[0].value];
          }
        } else {
          tempValue = newValue;
        }

        onChange({ target: { name, value: tempValue } });
      }
    },
    [name, multiple, disabled, normalizedValue, flattenOptionList, onChange]
  );

  /**
   * Gère le focus sur le select (custom).
   */
  const handleFocus = useCallback(() => {
    setFocused(true);
    setOpened(true);
  }, []);

  /**
   * Gère le clic sur un élément (custom).
   */
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        !(
          ($list.current instanceof HTMLElement
            ? $list.current.contains(event.target as HTMLElement)
            : false) || reference.contains(event.target as HTMLElement)
        )
      ) {
        setFocused(false);
        setOpened(false);
      }
    },
    [reference]
  );

  /**
   * Gère l'utilisation du clavier.
   */
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.code) {
        case "ArrowDown": {
          event.preventDefault();

          if (!opened) {
            setOpened(true);

            const firstOption = getFirstNonDisabledOption({
              optionList: flattenOptionList,
              fromIndex: 0,
              incremental: false,
            });

            if (null !== firstOption) {
              setHover(firstOption.value);
            }
          } else {
            const currentOptionIndex = flattenOptionList.findIndex(
              (option) => hover === option.value
            );

            const nextOption = getFirstNonDisabledOption({
              optionList: flattenOptionList,
              fromIndex: currentOptionIndex,
              incremental: true,
            });

            if (null !== nextOption) {
              setHover(nextOption.value);
            }
          }

          break;
        }

        case "ArrowUp": {
          event.preventDefault();

          if (!opened) {
            setOpened(true);

            const lastOption = getFirstNonDisabledOption({
              optionList: flattenOptionList,
              fromIndex: flattenOptionList.length - 1,
              incremental: false,
            });

            if (null !== lastOption) {
              setHover(lastOption.value);
            }
          } else {
            const currentOptionIndex = flattenOptionList.findIndex(
              (option) => hover === option.value
            );

            if (currentOptionIndex > -1) {
              const previousOption = getFirstNonDisabledOption({
                optionList: flattenOptionList,
                fromIndex: currentOptionIndex,
                incremental: false,
              });

              if (null !== previousOption) {
                setHover(previousOption.value);
              }
            } else {
              const lastOption = getFirstNonDisabledOption({
                optionList: flattenOptionList,
                fromIndex: flattenOptionList.length - 1,
                incremental: false,
              });

              if (null !== lastOption) {
                setHover(lastOption.value);
              }
            }
          }

          break;
        }

        case "Home": {
          event.preventDefault();

          if (!opened) {
            setOpened(true);
            setHover("");
          } else {
            if (Object.prototype.hasOwnProperty.call(flattenOptionList, "0")) {
              const firstOption = flattenOptionList[0];

              setHover(firstOption.value);
            } else {
              setHover("");
            }
          }

          break;
        }

        case "End": {
          event.preventDefault();

          if (!opened) {
            setOpened(true);
            setHover("");
          } else {
            if (
              flattenOptionList.length > 0 &&
              Object.prototype.hasOwnProperty.call(
                flattenOptionList,
                flattenOptionList.length - 1
              )
            ) {
              const lastOption =
                flattenOptionList[flattenOptionList.length - 1];

              setHover(lastOption.value);
            } else {
              setHover("");
            }
          }

          break;
        }

        case "Enter":
        case "Space": {
          if (!opened) {
            setOpened(true);
            setHover("");
          } else {
            event.preventDefault();

            setValueCustom(hover);
          }

          break;
        }

        case "Escape": {
          if (opened) {
            event.preventDefault();

            setOpened(false);
            setHover(null);
          }

          break;
        }
      }
    },
    [opened, hover, flattenOptionList, setValueCustom]
  );

  const handleChangeValueCustom = setValueCustom;

  const handleChangeValueNative = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!disabled) {
        const value = Array.from(event.target.options).reduce<
          Array<string | number>
        >((value, option) => {
          if (option.selected) {
            value.push(option.value);
          }

          return value;
        }, []);

        onChange({
          target: {
            name,
            value: multiple
              ? value
              : Object.prototype.hasOwnProperty.call(value, "0")
              ? value[0]
              : null,
          },
        });
      }
    },
    [name, multiple, disabled, onChange]
  );

  /**
   * Gère le survol d'un élément.
   */
  const handleHover = useCallback((value) => {
    setHover(value);
  }, []);

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      select__custom: {
        fontFamily: theme.FONT_FAMILY,
      },
    });
  }, [theme]);

  /**
   * Greffe les évémenents de clic au montage.
   */
  useEffect(() => {
    if (focused) {
      window.document.addEventListener("click", handleClick);
    }

    return () => {
      window.document.removeEventListener("click", handleClick);
    };
  }, [focused, handleClick]);

  /**
   * Greffe les événements du clavier au montage.
   */
  useEffect(() => {
    if (reference instanceof HTMLElement) {
      if (focused) {
        window.document.addEventListener("keydown", handleKeydown);
      }

      return () => {
        window.document.removeEventListener("keydown", handleKeydown);
      };
    }
  }, [focused, reference, handleKeydown]);

  const [selectId] = useState(`${Math.random()}`);

  const generatedOptionListTree = useMemo(
    () => generateOptionListTree({ optionList: nestedOptionList }),
    [nestedOptionList]
  );

  const selectedOption = multiple
    ? normalizedValue.length === 0
      ? [flattenOptionList[0]]
      : normalizedValue.map((v) =>
          flattenOptionList.find((option) => option.value === v)
        )
    : flattenOptionList.find((option) => option.value === value) ||
      flattenOptionList[0];

  return (
    <SelectProvider
      value={{
        native,
        value,
        hover,
        disabled,
        multiple,
        selectId,
        onChangeValue: handleChangeValueCustom,
        onHover: handleHover,
      }}
    >
      <div className={css(styles.wrapper)}>
        {native ? (
          <select
            className={css(
              styles.select__native,
              !multiple && styles.select__nativeSingle
            )}
            style={{
              fontFamily: theme.FONT_FAMILY,
            }}
            {...selectProps}
            required={required}
            disabled={disabled}
            multiple={multiple}
            value={value}
            onChange={handleChangeValueNative}
          >
            {generatedOptionListTree}
          </select>
        ) : (
          <div
            className={css(styles.select__custom, dynamicStyles.select__custom)}
            role="button"
            {...selectProps}
            aria-disabled={disabled}
            aria-expanded={opened}
            aria-haspopup="listbox"
          >
            <Popover
              container={
                <div
                  aria-haspopup="listbox"
                  className={css(styles.button)}
                  tabIndex={0}
                  onFocus={handleFocus}
                  ref={setReference}
                >
                  {multiple
                    ? selectedOption
                        .map((option, index) =>
                          get(option, "label", normalizedValue[index])
                        )
                        .join(", ")
                    : selectedOption.label}
                </div>
              }
              popover={
                <div role="listbox" className={css(styles.list)} ref={$list}>
                  {generatedOptionListTree}
                </div>
              }
              reference={reference}
              visible={opened}
            />
          </div>
        )}

        <div className={css(styles.caret)}>
          <IconCaretDownO />
        </div>

        {required && <div className={css(styles.required)}>*</div>}
      </div>
    </SelectProvider>
  );
};

Select.defaultProps = {
  required: false,
  disabled: false,
  native: true,
};

export default Select;
