// dependencies
import React, { useState, useRef, useMemo, useImperativeHandle } from "react";
import { StyleSheet, css } from "aphrodite";

// constants
import theming from "../../constants/theming";

// libraries
import { noop } from "@gdf/shared/src/libraries";

const { useTheme } = theming;

const styles = StyleSheet.create({
  textInput: {
    display: "flex",
    fontSize: "1rem",
    backgroundColor: "#ffffff",
    borderColor: "#cfdadd",
    borderWidth: "0.0625rem",
    borderStyle: "solid",
    borderRadius: "0.1875rem",
    position: "relative",
    ":after": {
      content: "''",
      position: "absolute",
      top: -1,
      right: -1,
      bottom: -1,
      left: -1,
      borderStyle: "solid",
      borderColor: "transparent",
      borderWidth: 2,
      borderRadius: "0.1875rem",
    },
  },
  textInput__focus: {
    ":after": {
      borderColor: "#000",
    },
  },
  inputContainer: {
    position: "relative",
    flexGrow: 1,
    zIndex: 1,
    minHeight: "2.375rem",
    display: "flex",
    alignItems: "center",
  },
  required: {
    position: "absolute",
    right: 2,
    top: 1,
    color: "#ff0000",
    fontSize: "0.625rem",
  },
  input: {
    display: "block",
    fontSize: "1rem",
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    ":focus": {
      outline: "none",
    },
  },
  input__inline: {},
  input__multiline: {
    paddingTop: "0.6875rem",
    paddingBottom: "0.6875rem",
  },
  input__disabled: {
    cursor: "not-allowed",
  },
  icon: {
    alignSelf: "center",
    position: "relative",
    zIndex: 1,
  },
  icon__left: {
    marginLeft: "0.5rem",
  },
  icon__right: {
    marginRight: "0.5rem",
  },
});

const getProps = ({ props, dynamicStyles }) => {
  const { name, type, value, id, disabled, readOnly, label, aStyle } = props;

  const placeholder = props.placeholder ? props.placeholder : label;

  const required = disabled ? false : props.required;

  return {
    name,
    type: "multiline" !== type ? type : undefined,
    value,
    id,
    disabled,
    className: css(
      styles.input,
      "multiline" !== type ? styles.input__inline : styles.input__multiline,
      dynamicStyles.input,
      disabled && styles.input__disabled,
      aStyle
    ),
    readOnly,
    required,
    placeholder,
    "aria-label": label,
    title: label,
  };
};

type PropsType = {
  name: string;
  label: string;
  type?: "text" | "password" | "multiline" | "number" | "date";
  value: string;
  required?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
  aStyle?;
  placeholder?: string;
  disabled?: boolean;
  id: string;
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  numberOfLines?: number;
  shouldRenderRequiredIcon?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
} & React.HTMLProps<HTMLInputElement>;

const TextInput = React.forwardRef(function TextInput(
  props: PropsType,
  ref: React.Ref<HTMLInputElement>
) {
  const {
    id,
    type,
    disabled,
    leftAddon,
    rightAddon,
    leftIcon,
    rightIcon,
    numberOfLines,
    style,
    aStyle,
    shouldRenderRequiredIcon,
    onChange,
    onFocus,
    onBlur,
    ...otherProps
  } = props;

  const theme = useTheme();

  const [focused, setFocused] = useState(false);

  const required = disabled ? false : props.required;

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      textInput__disabled: {
        borderColor: "#CFDADD",
        backgroundColor: "#CFDADD",
        color: theme.PRIMARY_COLOR_TEXT,
        cursor: "not-allowed",
        ":hover": {
          borderColor: "#CFDADD",
          backgroundColor: "#CFDADD",
          color: theme.PRIMARY_COLOR_TEXT,
        },
        ":active": {
          borderColor: "#CFDADD",
          backgroundColor: "#CFDADD",
          color: theme.PRIMARY_COLOR_TEXT,
        },
      },
      input: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.PRIMARY_COLOR_TEXT,
        "::placeholder": {
          fontFamily: theme.FONT_FAMILY,
          color: "#999999",
        },
      },
    });
  }, [theme]);

  const $input = useRef<any>();

  useImperativeHandle(ref, () => $input.current);

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocused(true);

    event.persist();

    onFocus(event);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocused(false);

    event.persist();

    onBlur(event);
  };

  return (
    <label
      className={css(
        styles.textInput,
        focused && styles.textInput__focus,
        disabled && dynamicStyles.textInput__disabled
      )}
      style={style}
      htmlFor={id}
    >
      {leftAddon && null}

      {leftIcon && (
        <div className={css(styles.icon, styles.icon__left)}>{leftIcon}</div>
      )}

      <div className={css(styles.inputContainer)}>
        {type === "multiline" ? (
          <textarea
            ref={$input}
            {...otherProps}
            {...getProps({ props, dynamicStyles })}
            rows={numberOfLines}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...(!disabled && { onChange })}
          />
        ) : (
          <input
            ref={$input}
            {...otherProps}
            {...getProps({ props, dynamicStyles })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...(!disabled && { onChange })}
          />
        )}

        {required && shouldRenderRequiredIcon && (
          <div className={css(styles.required)}>*</div>
        )}
      </div>

      {rightIcon && (
        <div className={css(styles.icon, styles.icon__right)}>{rightIcon}</div>
      )}

      {rightAddon && rightAddon}
    </label>
  );
});

TextInput.defaultProps = {
  type: "text",
  required: false,
  disabled: false,
  readOnly: false,
  shouldRenderRequiredIcon: true,
  onFocus: noop,
  onBlur: noop,
  onChange: noop,
};

export default TextInput;
